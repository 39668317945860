<template>
    <v-snackbar
        class="global-alert-notification-snackbar"
        v-model="stateGlobalAlert.show"
        :timeout="stateGlobalAlert.timeout"
        :color="stateGlobalAlert.type"
        location="top"
    >
        <span
            class="text-subtitle-1 font-weight-bold"
            v-html="stateGlobalAlert.text"
        ></span>
        <!-- Custom Button -->
        <template
            v-if="stateGlobalAlert.link"
            #actions
        >
            <v-btn
                :color="stateGlobalAlert.link.color"
                variant="flat"
                :to="stateGlobalAlert.link.to"
            >
                {{ stateGlobalAlert.link.label }}
            </v-btn>
        </template>
        <!-- Reload Button -->
        <template
            v-else-if="stateGlobalAlert.showReloadButton"
            #actions
        >
            <v-btn
                color="rgb(var(--v-theme-on-secondary))"
                class="text-secondary"
                variant="flat"
                @click="router.go(0)"
            >
                Reload
            </v-btn>
        </template>
        <!-- Close Button -->
        <template
            v-else-if="stateGlobalAlert.showCloseButton"
            #actions
        >
            <v-btn
                color="rgb(var(--v-theme-on-secondary))"
                class="text-secondary"
                variant="flat"
                @click="stateGlobalAlert.show = false"
            >
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script lang="ts" setup>
    import { stateGlobalAlert } from '~/composables';
    const router = useRouter();
</script>

<style lang="scss"></style>
